import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import {
  Spin,
  Form,
  Input,
  DatePicker,
  Radio,
  notification,
  Modal,
} from "antd";
import moment from "moment";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { IPopularRoute, MidRoute, TravelTime } from "../../types";
import { Option, RouteFare, Route } from "../../types";
import { getCookie, getTime } from "../../helpers/utils";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import { debounce } from "lodash";
import Select from "react-select";
import CustomButton from "../../components/CustomBtn";
import Loader from "../../components/Loader";
import PhoneInput from "react-phone-number-input";
import { ChevronUpIcon, XMarkIcon } from "@heroicons/react/24/solid";
import BusStopModal from "../../components/Schedules/BusStopModal";
import AuthPrompt from "../../components/Schedules/AuthPrompt";
import LuggageModal from "../../components/Schedules/LuggageModal";

interface TicketPrice {
  fare: number;
  serviceFee: number;
  amountPayable: number;
}

type LuggageType = {
  code: string;
  name: string;
  description: string;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585"
          stroke="#484442"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const BookInAdvanceMobile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedTime, setSelectedTime] = useState<any>();

  const [step, setStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [routes, setRoutes] = useState<Option[]>([]);
  const [date, setDate] = useState<string>("");
  const [loadingOrigins, setLoadingOrigins] = useState(false);
  const token = getCookie("oya_token");
  const [schedule, setSchedule] = useState<any>();
  const [routePrices, setRoutePrices] = useState<RouteFare[]>([]);

  const [numOfSeats, setNumOfSeats] = useState<number>(1);
  const [numOfMinors, setNumOfMinors] = useState<number>(0);
  const [luggage_description, setLuggageDescription] = useState("");
  const [message, setMessage] = useState("");

  const [pickupType, setPickupType] = useState<number>(0);
  const [selectedMidRoute, setSelectedMidRoute] = useState<any>();
  const [selectedLoadingPoint, setSelectedLoadingPoint] = useState<any>();
  const [loadingRoutes, setLoadingRoutes] = useState(false);

  const [phone, setPhone] = useState<string | undefined>();
  const [icePhone, setIcePhone] = useState<string | undefined>();
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [priceDetails, setPriceDetails] = useState<TicketPrice>();

  const [luggageType, setLuggageType] = useState<number>(2);
  const [selectedLuggage, setSelectedLuggage] = useState<any>([]);
  const [loadingPointId, setLoadingPointId] = useState<number>(0);
  const [popularRoutes, setPopularRoutes] = useState<IPopularRoute[]>([]);

  const [travelTime, setTravelTime] = useState<TravelTime[]>([]);

  const [busStopModal, openBusStopModal] = useState(false);
  const [luggageModal, openLuggageModal] = useState(false);

  const [fareId, setFareId] = useState<string | null>(null);
  const [timeId, setTimeId] = useState<string | null>(null);
  const [luggageSizes, setLuggageSizes] = useState<LuggageType[]>([]);
  const [loadingLuggageSizes, setLoadingLuggageSizes] = useState(false);
  const [promptUser, setPrompt] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(false);

  const [fetchingMidroute, setFetchingMidroute] = useState(false);

  const [success, setSuccess] = useState(false);

  const [pickups, setPickups] = useState<Option[]>([]);

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const tripData: any = localStorage.getItem("trd");
  const tripDataJson = JSON.parse(tripData);

  const getLuggageSizes = async () => {
    setLoadingLuggageSizes(true);
    try {
      const { data } = await api.get(RouteConstants.LUGGAGE_SIZE);

      localStorage.setItem("lsar", JSON.stringify(data?.payload?.items));

      setLuggageSizes(data?.payload?.items);
      setLoadingLuggageSizes(false);
    } catch (e) {
      setLoadingLuggageSizes(false);
      console.log(e);
    }
  };

  const getRoutes = async () => {
    try {
      setLoadingOrigins(true);
      const res = await api.get(RouteConstants.ROUTES);

      setRoutes(
        res.data?.payload?.items?.map((items: Route) => ({
          label: `${items?.from?.name} - ${items?.to?.name}`,
          value: items?.id,
        }))
      );

      setLoadingOrigins(false);
    } catch (e) {
      setLoadingOrigins(false);

      console.log(e);
    }
  };

  const getMidroutes = async (routeId: number | undefined) => {
    //console.log("Route", routeId);
    if (routeId) {
      setFetchingMidroute(true);
      try {
        const filter = [
          {
            f: "route.id",
            o: "=",
            p: [routeId],
          },
        ];
        const res = await api.get(
          `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filter)}`
        );

        setPickups(
          res.data?.payload?.items?.map((item: MidRoute) => ({
            label: item?.pickup?.name,
            value: item?.pickup?.id,
          }))
        );
      } catch (e) {
        console.log(e);
      } finally {
        setFetchingMidroute(false);
        //setLoading(false);
      }
    }
  };

  const loadRoutes = debounce(
    async (value: string, callback: (options: Option[]) => void) => {
      setLoadingOrigins(true);
      let filterData = [
        {
          f: "from.name",
          o: "contains",
          p: [`${value}`],
          c: "OR",
        },
        {
          f: "to.name",
          o: "contains",
          p: [`${value}`],
        },
      ];

      try {
        const res = await api.get(
          `${RouteConstants.ROUTES}?filters=${JSON.stringify(filterData)}`
        );

        //setRoutes(res.data?.payload?.items);
        const options = res.data?.payload?.items?.map((items: Route) => ({
          label: `${items?.from?.name} - ${items?.to?.name}`,
          value: items?.id,
        }));
        callback(options);
        setLoadingOrigins(false);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        setLoadingOrigins(false);
      }
    },
    2000
  );

  const getRoutePrices = async (e: number | undefined) => {
    setSubmitting(true);
    if (e) {
      try {
        const { data } = await api.get(
          `${RouteConstants.ROUTES}/${e}/loading-point-prices`
        );
        //console.log(data?.payload?.items);
        setRoutePrices(data?.payload?.items);
      } catch (e: any) {
        //console.log(e);
        notification.error({
          message: e?.response?.data?.message || e?.message,
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const getTravelTimes = async () => {
    setSubmitting(true);
    try {
      const { data } = await api.get(`${RouteConstants.TRAVEL_TIME}`);

      setTravelTime(data?.payload?.items);
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getPopularRoutes = async () => {
    setLoadingRoutes(true);
    try {
      const { data } = await api.get(RouteConstants.POPULAR_ROUTES);
      setPopularRoutes(data?.payload?.items);
      setLoadingRoutes(false);
    } catch (error) {
      setLoadingRoutes(false);
    }
  };

  const getAdvanceBookingTicketPrice = async () => {
    setLoadingPrices(true);
    try {
      let payload: any = {
        loading_point_price_id: fareId,
        num_of_seats: numOfSeats,
      };

      if (selectedMidRoute) {
        payload.pickup_id = selectedMidRoute?.value;
      }

      const { data } = await api.post(
        RouteConstants.GET_ADVANCE_TICKET_PRICE,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPriceDetails(data?.payload);

      setLoadingPrices(false);
    } catch (error) {
      setLoadingPrices(true);
      console.log(error);
    }
  };

  const localData = async () => {
    form.setFieldValue("route_id", tripDataJson?.route_id);
    form.setFieldValue("date", moment(tripDataJson?.date));
    setSchedule(tripDataJson?.route_id);
    getRoutePrices(tripDataJson?.route_id?.value);
    await getMidroutes(tripDataJson?.route_id?.value);

    setFareId(tripDataJson?.fareId);
    setLoadingPointId(tripDataJson?.loading_point_id);

    setPickupType(tripDataJson?.pickupType);

    setSelectedTime(tripDataJson?.travel_time);
    form.setFieldValue("travel_time", tripDataJson?.travel_time);
    setTimeId(tripDataJson?.travel_time?.value);

    setNumOfSeats(tripDataJson?.seats);
    setNumOfMinors(tripDataJson?.minors);

    form.setFieldValue("message", tripDataJson?.message);
    form.setFieldValue(
      "luggage_description",
      tripDataJson?.luggage_description
    );
    setSelectedMidRoute(tripDataJson?.midroute);

    if (!token) {
      form.setFieldValue("first_name", tripDataJson?.passenger?.first_name);
      form.setFieldValue("last_name", tripDataJson?.passenger?.last_name);
      form.setFieldValue("guest_phone", tripDataJson?.passenger?.phone);
      form.setFieldValue("user_ice_phone", tripDataJson?.passenger?.ice_phone);
      setPhone(tripDataJson?.passenger?.phone);
      setIcePhone(tripDataJson?.passenger?.ice_phone);
    }
  };

  const handleRouteChange = async (e: Option | null) => {
    //console.log(e);
    localStorage.setItem("rid", JSON.stringify(e));
    setSchedule(e);
    await getMidroutes(e?.value);
    getRoutePrices(e?.value);
  };

  const bookInAdvance = async () => {
    setSubmitting(true);
    try {
      const travel_date = form.getFieldValue("date");
      const travel_time = form.getFieldValue("travel_time");

      //const time = moment(travel_time?.$d).format();
      //console.log(travel_time?.value);

      const route = form.getFieldValue("route_id");
      const travelTimeItem = travelTime.find(
        (item) => item?.id === travel_time?.value
      );

      const startTravelTime = new Date(
        `1970-01-01T${travelTimeItem?.start_time}Z`
      );
      const formatStartTime = moment(startTravelTime).format();
      const endTravelTime = new Date(`1970-01-01T${travelTimeItem?.end_time}Z`);
      const formatEndTime = moment(endTravelTime).format();
      //console.log(travelTimeItem);

      const body = {
        minors: numOfMinors,
        message: message ? message : undefined,
        route_id: route?.value,
        min_travel_time: `${moment(travel_date?.$d).format("YYYY-MM-DD")}T${
          formatStartTime.split("T")[1]
        }`,
        max_travel_time: `${moment(travel_date?.$d).format("YYYY-MM-DD")}T${
          formatEndTime.split("T")[1]
        }`,
      };

      //console.log(body);
      await api.post(RouteConstants.ADVANCE_BOOKING, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccess(true);

      form.resetFields();
      localStorage.removeItem("rid");
      localStorage.removeItem("trd");
      setSchedule(null);
      setNumOfMinors(0);
      setSelectedTime(null);
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const submit = async () => {
    form
      .validateFields(["date", "route_id", "travel_time"])
      .then(async (val) => {
        //case 1
        //no trip fare available
        if (!fareId) {
          //user is not logged in
          if (!token) {
            //show
            setPrompt(true);
          } else {
            bookInAdvance();
          }
        } else if (!token) {
          if (step === 2) {
            guestAdvanceTicket();
          } else {
            setStep(2);
          }
        } else {
          setSubmitting(true);
          //check travel time
          if (timeId) {
            const values = form.getFieldsValue();

            try {
              setSubmitting(true);

              console.log(values);
              const travel_date = form.getFieldValue("date");

              const actual_date = travel_date?.$d
                ? moment(travel_date?.$d).format()
                : moment(travel_date).format();

              const payload = {
                loading_point_id: loadingPointId,
                loading_point_price_id: fareId,
                loading_point_travel_time_id: timeId,
                luggage_sizes:
                  luggageType === 3
                    ? selectedLuggage?.length > 0
                      ? selectedLuggage?.map((item: any) => ({
                          luggage_size_code: item?.code,
                          number_of_luggages: item?.quantity,
                        }))
                      : undefined
                    : undefined,
                pickup_id:
                  pickupType !== 0 ? selectedMidRoute.value : undefined,
                minors: numOfMinors,
                travel_date: actual_date,
                num_of_seats: numOfSeats,
                message: message ? message : undefined,
                luggage_description: luggage_description
                  ? luggage_description
                  : undefined,
              };

              const res = await api.post(
                RouteConstants.LOADING_POINT_BOOKING,
                {
                  ...payload,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              //console.log(res?.data);

              const fields = {
                minors: numOfMinors,
                seats: numOfSeats,
                boarding: pickupType === 0 ? "Bus terminal" : "Mid-route",
                amount_payable: res?.data?.payload?.amount_payable,
                id: res?.data?.payload?.id,
                price: res?.data?.payload?.price,
                service_charge: res?.data?.payload?.total_service_fee,
                date: actual_date,
                //luggage: selectedLuggage && JSON.stringify(selectedLuggage),
              };

              const savedFields = {
                route_id: val?.route_id,
                pickupType,
                travel_time: selectedTime,
                fareId,
                loading_point_id: loadingPointId,
                pickup_id:
                  selectedMidRoute && selectedMidRoute.value !== 0
                    ? selectedMidRoute.value
                    : undefined,
                minors: numOfMinors,
                travel_date: actual_date,
                seats: numOfSeats,
                message,
                date: form.getFieldValue("date"),
                midroute: selectedMidRoute && selectedMidRoute,
                luggage_description,
              };

              localStorage.setItem("trd", JSON.stringify(savedFields));

              localStorage.setItem("trf", JSON.stringify(fields));

              navigate(`/advance-ticket/pay/${res?.data?.payload?.id}`);
              setSubmitting(false);
            } catch (e: any) {
              //console.log(e);
              setSubmitting(false);
              notification.error({
                message: e?.response?.data?.message || e?.message,
              });
            }
          } else {
            setSubmitting(false);
            notification.info({
              message: "Please select your preferred travel time",
            });
          }
        }
      })
      .catch((e) => {
        //handle errors
        notification.info({
          message: "Please provide all required information",
        });
      });
  };

  const guestAdvanceTicket = () => {
    form
      .validateFields([
        "first_name",
        "last_name",
        "guest_phone",
        "user_ice_phone",
      ])
      .then(async (values) => {
        setSubmitting(true);
        if (fareId) {
          try {
            const travel_date = form.getFieldValue("date");
            const actual_date = travel_date?.$d
              ? moment(travel_date?.$d).format()
              : moment(travel_date).format();

            const payload = {
              loading_point_id: loadingPointId,
              loading_point_price_id: fareId,
              loading_point_travel_time_id: timeId,
              pickup_id: pickupType !== 0 ? selectedMidRoute.value : undefined,
              minors: numOfMinors,
              num_of_seats: numOfSeats,
              message: message ? message : undefined,
              travel_date: actual_date,
              luggage_sizes:
                luggageType === 3
                  ? selectedLuggage?.length > 0
                    ? selectedLuggage?.map((item: any) => ({
                        luggage_size_code: item?.code,
                        number_of_luggages: item?.quantity,
                      }))
                    : undefined
                  : undefined,
              luggage_description: luggage_description
                ? luggage_description
                : undefined,
              passenger: {
                first_name: values?.first_name,
                last_name: values?.last_name,
                phone,
                ice_phone: icePhone,
              },
            };
            const { data } = await api.post(
              RouteConstants.GUEST_ADVANCE_TICKET,
              {
                ...payload,
              }
            );

            const fields = {
              minors: numOfMinors,
              seats: numOfSeats,
              boarding: pickupType === 0 ? "Bus terminal" : "Mid-route",
              amount_payable: data?.payload?.amount_payable,
              id: data?.payload?.id,
              price: data?.payload?.price,
              service_charge: data?.payload?.total_service_fee,
              date: actual_date,
              //luggage: selectedLuggage && JSON.stringify(selectedLuggage),
              passenger: !token && {
                first_name: values?.first_name,
                last_name: values?.last_name,
                phone,
                ice_phone: icePhone,
              },
            };

            const savedFields = {
              route_id: schedule,
              pickupType,
              travel_time: selectedTime,
              fareId,
              loading_point_id: loadingPointId,
              pickup_id:
                selectedMidRoute?.value !== 0
                  ? selectedMidRoute?.value
                  : undefined,
              minors: numOfMinors,
              travel_date: actual_date,
              seats: numOfSeats,
              message: values?.message && values?.message,
              date: form.getFieldValue("date"),
              midroute: selectedMidRoute && selectedMidRoute,
              luggage_description:
                values?.luggage_description && values?.luggage_description,
              passenger: !token && {
                first_name: values?.first_name,
                last_name: values?.last_name,
                phone,
                ice_phone: icePhone,
              },
            };

            localStorage.setItem("trd", JSON.stringify(savedFields));

            localStorage.setItem("trf", JSON.stringify(fields));

            navigate(`/advance-ticket/pay/${data?.payload?.id}`);
            setSubmitting(false);
          } catch (e: any) {
            notification.error({
              message: e?.response?.data?.message || e?.message,
            });
            setSubmitting(false);
          }
        } else {
          try {
            const travel_date = form.getFieldValue("date");
            const travel_time = form.getFieldValue("travel_time");

            //const time = moment(travel_time?.$d).format();
            console.log(date);

            const route = form.getFieldValue("route_id");

            const travelTimeItem = travelTime.find(
              (item) => item?.id === travel_time?.value
            );

            const startTravelTime = new Date(
              `1970-01-01T${travelTimeItem?.start_time}Z`
            );
            const formatStartTime = moment(startTravelTime).format();
            const endTravelTime = new Date(
              `1970-01-01T${travelTimeItem?.end_time}Z`
            );
            const formatEndTime = moment(endTravelTime).format();

            const payload = {
              minors: numOfMinors,
              message: message ? message : undefined,
              route_id: route?.value,
              min_travel_time: `${moment(travel_date?.$d).format(
                "YYYY-MM-DD"
              )}T${formatStartTime.split("T")[1]}`,
              max_travel_time: `${moment(travel_date?.$d).format(
                "YYYY-MM-DD"
              )}T${formatEndTime.split("T")[1]}`,
              passenger: {
                minor_count: numOfMinors,
                seat_id: numOfSeats,
                luggage_description:
                  form.getFieldValue("luggage_description") &&
                  form.getFieldValue("luggage_description"),
                phone: phone,
                first_name: values?.first_name,
                last_name: values?.last_name,
                ice_phone: icePhone,
              },
            };
            await api.post(RouteConstants.ADVANCE_BOOKING_GUEST, {
              ...payload,
            });

            // notification.success({
            //   message: `Your advance booking to ${route?.label} has been made`,
            // });
            setSuccess(true);

            form.resetFields();
            setSchedule(null);
            setStep(1);
            localStorage.removeItem("rid");
            setNumOfMinors(0);
            setSubmitting(false);
            setSelectedTime(null);
          } catch (e: any) {
            //console.log(e);
            notification.error({
              message: e?.response?.data?.message || e?.message,
            });
            setSubmitting(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (tripDataJson) {
      //console.log("trip saved data", tripDataJson);
      localData();
    }
    getTravelTimes();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fareId) {
      getAdvanceBookingTicketPrice();
    }

    // eslint-disable-next-line
  }, [fareId, selectedMidRoute, numOfSeats]);

  useEffect(() => {
    getRoutes();
    getLuggageSizes();
    getPopularRoutes();

    localStorage.removeItem("pfd");
    localStorage.removeItem("trf");
    localStorage.removeItem("rid");
    localStorage.removeItem("ulgs");

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Spin spinning={submitting} indicator={<Loader />}>
        <Helmet>
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content={"#B40303"}
          />
          <meta name="theme-color" content={"#fff"} />
        </Helmet>

        <div className="bg-white relative min-h-screen overflow-hidden">
          <div className="top-0 fixed z-[100] w-full">
            <div className="relative w-full inline-flex">
              <div className="bg-white h-[44px] w-full pt-5 pb-3 px-[15px] ">
                <div className="flex items-center">
                  <div className="flex-[0.3]">
                    <button onClick={() => navigate(-1)}>
                      <ChevronLeftIcon className="w-7 h-7 " />
                    </button>
                  </div>
                  <div className="flex-[0.7]">
                    <div className="header-text-4">Book in advance</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-[4rem]">
            <div className=" min-h-screen overflow-y-auto">
              <div>
                <Form
                  className={`book-advance-container-mobile sm:w-[65%] rounded-[16px] lg:p-11 p-7 ${
                    routePrices?.length > 0 && "flex-[0.7]"
                  }`}
                  form={form}
                  layout="vertical"
                  autoComplete="false"
                >
                  <>
                    {step === 1 && (
                      <>
                        {schedule && (
                          <div className="font-bold text-[18px] pb-5">
                            Trip route
                          </div>
                        )}
                        <Form.Item
                          className="w-full"
                          name="route_id"
                          label={
                            <label className="text-label">
                              Where are you going?
                            </label>
                          }
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <AsyncSelect
                            isClearable
                            isLoading={loadingOrigins}
                            defaultOptions={routes}
                            defaultValue={schedule}
                            loadOptions={(inputValue, callback) => {
                              loadRoutes(inputValue, callback);
                            }}
                            placeholder="Select your route"
                            components={{
                              DropdownIndicator,
                            }}
                            onChange={(e) => {
                              setPickupType(0);
                              setSelectedMidRoute(null);
                              if (e) {
                                handleRouteChange(e);
                              }
                            }}
                            styles={{
                              container(base, props) {
                                return {
                                  ...base,
                                  height: "48px",
                                  borderWidth: "1px",
                                  borderRadius: "8px",
                                  borderColor: "#939291",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                };
                              },
                              control(base, props) {
                                return {
                                  ":active": {
                                    borderColor: "#ccc",
                                  },
                                  width: "100%",
                                  display: "flex",
                                };
                              },
                            }}
                          />
                        </Form.Item>

                        {!schedule && (
                          <div>
                            <div className="font-semibold pt-3 pb-1">
                              Popular routes
                            </div>
                            {loadingRoutes ? (
                              <div className="animate-pulse w-12 h-6 rounded-sm bg-[#F5F5F5]" />
                            ) : (
                              <div>
                                {popularRoutes.length > 0 && (
                                  <div className="flex items-center gap-2 flex-wrap">
                                    {popularRoutes.map((item) => (
                                      <button
                                        onClick={async () => {
                                          // console.log({
                                          //   value: item?.id,
                                          //   label: `${item?.from?.name} - ${item?.to?.name}`,
                                          // });
                                          localStorage.setItem(
                                            "rid",
                                            JSON.stringify({
                                              label: `${item?.from?.name} - ${item?.to?.name}`,
                                              value: item?.id,
                                            })
                                          );

                                          form.setFieldValue("route_id", {
                                            label: `${item?.from?.name} - ${item?.to?.name}`,
                                            value: item?.id,
                                          });
                                          setSchedule({
                                            label: `${item?.from?.name} - ${item?.to?.name}`,
                                            value: item?.id,
                                          });
                                          getMidroutes(item?.id);
                                          getRoutePrices(item?.id);
                                        }}
                                        key={item?.id}
                                      >
                                        <div className="bg-[#F5F5F5] rounded-sm p-1">
                                          {item?.from?.name} to {item?.to?.name}
                                        </div>
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {schedule && (
                          <>
                            {routePrices.length > 0 ? (
                              <>
                                {fetchingMidroute ? null : (
                                  <Form.Item
                                    name="pickup-type"
                                    label={
                                      <label className="text-label">
                                        Where will you join the trip?
                                      </label>
                                    }
                                  >
                                    <div className="w-full gap-6">
                                      <button
                                        data-testid="busTerminalPickUp"
                                        className={`rounded-lg p-2 border mb-5 w-full items-start ${
                                          pickupType === 0
                                            ? "border-oya-red-100"
                                            : "border-oya-gray-200"
                                        }`}
                                        onClick={() => {
                                          setPickupType(0);
                                          setSelectedMidRoute(null);
                                        }}
                                      >
                                        <div className="flex items-start justify-start text-left">
                                          <Radio
                                            defaultChecked
                                            onChange={(e) => {
                                              setPickupType(e.target.value);
                                              setSelectedMidRoute(null);
                                            }}
                                            checked={
                                              pickupType === 0 ? true : false
                                            }
                                            value={0}
                                          />
                                          <div>
                                            <div className="text-[14px]">
                                              Bus terminal
                                            </div>
                                            <div className="text-[10px] text-oya-gray-200">
                                              Join the Bus at the station
                                            </div>
                                          </div>
                                        </div>
                                      </button>

                                      <button
                                        data-testid="midRoutePickUp"
                                        className={`rounded-lg p-2 border flex-[0.5] w-full ${
                                          pickupType === 1
                                            ? "border-oya-red-100"
                                            : "border-oya-gray-200"
                                        }`}
                                        disabled={
                                          pickups.length > 0 ? false : true
                                        }
                                        onClick={() => openBusStopModal(true)}
                                      >
                                        <div className="flex items-start text-left">
                                          <Radio
                                            onClick={() => {
                                              openBusStopModal(true);
                                            }}
                                            checked={
                                              pickupType === 1 ? true : false
                                            }
                                            disabled={
                                              pickups.length > 0 ? false : true
                                            }
                                            value={1}
                                          />
                                          <div
                                            className={`${
                                              pickups.length > 0
                                                ? "text-black"
                                                : "text-oya-gray-200"
                                            }`}
                                          >
                                            <div className="texdt-[14px]">
                                              Mid-route
                                            </div>
                                            <div className="text-[10px] text-oya-gray-200">
                                              Join the bus at{" "}
                                              {selectedMidRoute?.label}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </Form.Item>
                                )}

                                <hr className="mt-8 mb-2" />

                                <div className="text-[18px] font-bold mt-5">
                                  Loading point
                                </div>
                                <div className="items-start mt-[32px] gap-6">
                                  {routePrices.map((item, index) => (
                                    <button
                                      data-testid="busFare"
                                      key={item.id}
                                      onClick={() => {
                                        setFareId(item?.id);
                                        setSelectedLoadingPoint(item);
                                        setLoadingPointId(
                                          item?.loading_point?.id
                                        );
                                      }}
                                      className={`px-2 w-full py-1 mb-5 rounded-lg border ${
                                        fareId === item?.id
                                          ? "border-oya-red-100"
                                          : "border-oya-gray-200"
                                      }`}
                                    >
                                      <div className="flex gap-1 items-start">
                                        <Radio
                                          checked={
                                            fareId === item.id ? true : false
                                          }
                                          onClick={() => {
                                            // let fields = {
                                            //   bus_type: "Urvan",
                                            //   price: item.price,
                                            //   price_id: item.id,
                                            // };
                                            // localStorage.setItem(
                                            //   "utf",
                                            //   JSON.stringify(fields)
                                            // );
                                            setSelectedLoadingPoint(item);

                                            setFareId(item.id);
                                            // getTravelTimes(
                                            //   item?.loading_point?.id
                                            // );
                                            setLoadingPointId(
                                              item?.loading_point?.id
                                            );
                                          }}
                                        />
                                        <div className="text-left">
                                          <div className="font-bold text-[14px] ">
                                            {item?.loading_point?.label}
                                          </div>

                                          <div className="mt-2 text-[12px]">
                                            {item?.branch?.name}
                                          </div>
                                          <div className=" text-[12px]">
                                            {item?.currency} {item?.price}
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  ))}
                                </div>

                                <hr className="mt-8 mb-2" />

                                <div className="text-heading-3 mt-5">
                                  Trip date
                                </div>

                                <div className="mt-[32px] lg:flex items-center gap-4">
                                  <Form.Item
                                    className="w-full"
                                    colon={false}
                                    label={
                                      <label className="text-label">
                                        When are you traveling?
                                      </label>
                                    }
                                    name="date"
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      className="w-full h-[50px] border-oya-gray-200"
                                      onChange={(date, dateString) => {
                                        setDate(dateString);
                                        //console.log(date, dateString);
                                      }}
                                      placeholder="Enter travel date"
                                      suffixIcon={
                                        <CalendarDaysIcon className="w-6 h-6 text-[#484442]" />
                                      }
                                      // disabled={submitting}
                                      disabledDate={disabledDate}
                                    />
                                  </Form.Item>
                                </div>

                                <div className="mt-8">
                                  <Form.Item
                                    name={"travel_time"}
                                    colon={false}
                                    label={
                                      <label className="text-label">
                                        Select preferred departure time
                                      </label>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      //defaultValue={selectedTime}
                                      options={travelTime.map((item) => ({
                                        label: `${
                                          item?.name
                                        } (Between ${getTime(
                                          item?.start_time
                                        )} and ${getTime(item?.end_time)})`,
                                        value: item?.id,
                                      }))}
                                      placeholder="Your travel time"
                                      onChange={(e) => {
                                        setSelectedTime(e);

                                        if (e) {
                                          setTimeId(e.value);
                                        }
                                      }}
                                      components={{
                                        DropdownIndicator,
                                      }}
                                      styles={{
                                        container(base, props) {
                                          return {
                                            ...base,
                                            height: "48px",
                                            borderWidth: "1px",
                                            borderRadius: "8px",
                                            borderColor: "#939291",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          };
                                        },
                                        control(base, props) {
                                          return {
                                            ":active": {
                                              borderColor: "#ccc",
                                            },
                                            width: "100%",
                                            display: "flex",
                                          };
                                        },
                                      }}
                                    />
                                  </Form.Item>
                                </div>

                                <hr className="mt-8 mb-2" />

                                <div className="font-bold text-[18px] mt-5">
                                  Passengers
                                </div>

                                <div className="mt-5 lg:flex items-center gap-5">
                                  <div className="lg:flex-[0.4] mb-4 lg:mb-0">
                                    <div className="text-[16px] mb-2">
                                      How many seats do you need?
                                    </div>
                                    <div className="flex items-center gap-4">
                                      <button
                                        data-testid="decrementSeats"
                                        onClick={() => {
                                          if (numOfSeats > 1) {
                                            setNumOfSeats(numOfSeats - 1);
                                          }
                                        }}
                                        className="rounded-btn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M4.16671 9.1665C3.70647 9.1665 3.33337 9.5396 3.33337 9.99984C3.33337 10.4601 3.70647 10.8332 4.16671 10.8332C11.172 10.8332 8.31424 10.8332 15.8334 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8334 9.1665C8.31425 9.1665 11.172 9.1665 4.16671 9.1665Z"
                                            fill="#939291"
                                          />
                                        </svg>
                                      </button>
                                      <div className="text-[18px] font-semibold">
                                        {numOfSeats}
                                      </div>
                                      <button
                                        data-testid="incrementSeats"
                                        onClick={() =>
                                          setNumOfSeats(numOfSeats + 1)
                                        }
                                        className="rounded-btn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M10.8334 4.16683C10.8334 3.70659 10.4603 3.3335 10 3.3335C9.5398 3.3335 9.16671 3.70659 9.16671 4.16683V9.16683H4.16671C3.70647 9.16683 3.33337 9.53992 3.33337 10.0002C3.33337 10.4604 3.70647 10.8335 4.16671 10.8335H9.16671V15.8335C9.16671 16.2937 9.5398 16.6668 10 16.6668C10.4603 16.6668 10.8334 16.2937 10.8334 15.8335V10.8335H15.8334C16.2936 10.8335 16.6667 10.4604 16.6667 10.0002C16.6667 9.53993 16.2936 9.16683 15.8334 9.16683H10.8334V4.16683Z"
                                            fill="#B40303"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="lg:flex-[0.6]">
                                    <div className="text-[16px] mb-2">
                                      How many children are you travelling with?
                                    </div>
                                    <div className="flex items-center gap-4">
                                      <button
                                        data-testid="decrementMinors1"
                                        onClick={() => {
                                          if (numOfMinors > 0) {
                                            setNumOfMinors(numOfMinors - 1);
                                          }
                                        }}
                                        className="rounded-btn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M4.16671 9.1665C3.70647 9.1665 3.33337 9.5396 3.33337 9.99984C3.33337 10.4601 3.70647 10.8332 4.16671 10.8332C11.172 10.8332 8.31424 10.8332 15.8334 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8334 9.1665C8.31425 9.1665 11.172 9.1665 4.16671 9.1665Z"
                                            fill="#939291"
                                          />
                                        </svg>
                                      </button>
                                      <div className="text-[18px] font-semibold">
                                        {numOfMinors}
                                      </div>
                                      <button
                                        data-testid="incrementMinors1"
                                        onClick={() =>
                                          setNumOfMinors(numOfMinors + 1)
                                        }
                                        className="rounded-btn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M10.8334 4.16683C10.8334 3.70659 10.4603 3.3335 10 3.3335C9.5398 3.3335 9.16671 3.70659 9.16671 4.16683V9.16683H4.16671C3.70647 9.16683 3.33337 9.53992 3.33337 10.0002C3.33337 10.4604 3.70647 10.8335 4.16671 10.8335H9.16671V15.8335C9.16671 16.2937 9.5398 16.6668 10 16.6668C10.4603 16.6668 10.8334 16.2937 10.8334 15.8335V10.8335H15.8334C16.2936 10.8335 16.6667 10.4604 16.6667 10.0002C16.6667 9.53993 16.2936 9.16683 15.8334 9.16683H10.8334V4.16683Z"
                                            fill="#B40303"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-8 mb-2" />
                                <div className="text-heading-3 mt-5">
                                  Luggage details
                                </div>
                                <Form.Item
                                  name="luggage-type"
                                  label={
                                    <label className="mt-2 text-[16px]">
                                      Bringing any luggage?
                                    </label>
                                  }
                                >
                                  <div className="w-full lg:flex justify-between">
                                    <Radio
                                      defaultChecked
                                      onChange={(e) => {
                                        setLuggageType(e.target.value);
                                        setSelectedLuggage([]);
                                      }}
                                      checked={luggageType === 2 ? true : false}
                                      className={`rounded-lg p-8 border flex-[0.5] w-full items-start ${
                                        luggageType === 2
                                          ? "border-oya-red-100"
                                          : "border-oya-gray-200"
                                      }`}
                                      value={2}
                                    >
                                      <div className="texdt-[16px]">
                                        No luggage
                                      </div>
                                      <div>No luggage, only personal item</div>
                                    </Radio>

                                    <Radio
                                      // onChange={(e) => {

                                      //   openLuggageModal(true);
                                      // }}
                                      disabled={loadingLuggageSizes}
                                      onClick={() => {
                                        openLuggageModal(true);
                                      }}
                                      checked={luggageType === 3 ? true : false}
                                      className={`rounded-lg p-8 border flex-[0.5] w-full mt-4 lg:mt-0 ${
                                        luggageType === 3
                                          ? "border-oya-red-100"
                                          : "border-oya-gray-200"
                                      }`}
                                      value={3}
                                    >
                                      <div className="texdt-[16px]">
                                        Luggage
                                      </div>
                                      {selectedLuggage?.length > 0 ? (
                                        <div className="flex flex-wrap items-center gap-2">
                                          {selectedLuggage?.map(
                                            (item: any, index: number) => (
                                              <div key={index} className="flex">
                                                <div className="flex items-center gap-1">
                                                  <div>{item?.quantity}</div>
                                                  <div>{item?.name}</div>
                                                </div>
                                                <div>
                                                  {index <
                                                  selectedLuggage?.length - 1
                                                    ? ", "
                                                    : ""}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div>Small, medium or large bags</div>
                                      )}
                                    </Radio>
                                  </div>
                                </Form.Item>

                                {luggageType === 3 && (
                                  <Form.Item
                                    colon={false}
                                    label="Any additional information about your luggage?"
                                    name="luggage_description"
                                    className="w-full mt-[32px]"
                                  >
                                    <Input.TextArea
                                      className="border border-gray-400 h-[170px] md:h-[96px] roundedLg mb-5 pl-[16px] pt-[12px]"
                                      rows={5}
                                      placeholder="Enter your message..."
                                      maxLength={100}
                                      onChange={(e) =>
                                        setLuggageDescription(e.target.value)
                                      }
                                    />
                                    <div className="w-full justify-end items-end text-oya-gray-200">
                                      {luggage_description.length} / 100
                                    </div>
                                  </Form.Item>
                                )}
                              </>
                            ) : (
                              <>
                                <hr className="mt-8 mb-2" />

                                <div className="text-heading-3 mt-5">
                                  Trip date & time
                                </div>

                                <div className="mt-[32px] lg:flex items-center gap-4">
                                  <Form.Item
                                    className="lg:w-1/2"
                                    colon={false}
                                    label={
                                      <label className="text-label">
                                        When are you traveling?
                                      </label>
                                    }
                                    name="date"
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      className="w-full h-[50px] border-oya-gray-200"
                                      onChange={(date, dateString) => {
                                        setDate(dateString);
                                        //console.log(date, dateString);
                                      }}
                                      placeholder="Enter travel date"
                                      suffixIcon={
                                        <CalendarDaysIcon className="text-[#484442] w-6 h-6" />
                                      }
                                      // disabled={submitting}
                                      disabledDate={disabledDate}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className="text-label">
                                        What time would you like to depart?
                                      </label>
                                    }
                                    name="travel_time"
                                    className="lg:w-1/2 "
                                  >
                                    {/* <TimePicker
                                      className="w-full h-[50px] border-oya-gray-200"
                                      // disabled={submitting}
                                      placeholder="Enter travel time"
                                    /> */}
                                    <Form.Item
                                      name={"travel_time"}
                                      colon={false}
                                      // label={
                                      //   <label className="text-label">
                                      //     Select preferred departure time
                                      //   </label>
                                      // }
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        defaultValue={selectedTime}
                                        options={travelTime.map((item) => ({
                                          label: `${
                                            item?.name
                                          } (Between ${getTime(
                                            item?.start_time
                                          )} and ${getTime(item?.end_time)})`,
                                          value: item?.id,
                                        }))}
                                        placeholder="Your travel time"
                                        isSearchable
                                        onChange={(e) => {
                                          setSelectedTime(e);

                                          if (e) {
                                            setTimeId(e.value);
                                          }
                                        }}
                                        components={{
                                          DropdownIndicator,
                                        }}
                                        styles={{
                                          container(base, props) {
                                            return {
                                              ...base,
                                              height: "48px",
                                              borderWidth: "1px",
                                              borderRadius: "8px",
                                              borderColor: "#939291",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            };
                                          },
                                          control(base, props) {
                                            return {
                                              ":active": {
                                                borderColor: "#ccc",
                                              },
                                              width: "100%",
                                              display: "flex",
                                            };
                                          },
                                        }}
                                      />
                                    </Form.Item>
                                  </Form.Item>
                                </div>

                                <div className="flex gap-2 items-center mt-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.99996 1.66683C14.5833 1.66683 18.3333 5.41683 18.3333 10.0002C18.3333 14.5835 14.5833 18.3335 9.99996 18.3335C5.41663 18.3335 1.66663 14.5835 1.66663 10.0002C1.66663 5.41683 5.41663 1.66683 9.99996 1.66683Z"
                                      stroke="#B40303"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M10 13.3335V9.16683"
                                      stroke="#B40303"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.99536 6.6665H10.0028"
                                      stroke="#B40303"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div>
                                    Departure time might delay due to slow
                                    loading of the bus.
                                  </div>
                                </div>

                                <hr className="mt-8 mb-2" />

                                <div className="text-heading-3 mt-5">
                                  Minors
                                </div>

                                <div className="mt-[32px] lg:flex items-center gap-5">
                                  <div className="lg:flex items-center gap-5">
                                    <div className="text-[16px] mb-2">
                                      How many children are you travelling with?
                                    </div>
                                    <div className="flex items-center gap-4">
                                      <button
                                        data-testid="decrementMinors2"
                                        onClick={() => {
                                          if (numOfMinors > 0) {
                                            setNumOfMinors(numOfMinors - 1);
                                          }
                                        }}
                                        className="rounded-btn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M4.16671 9.1665C3.70647 9.1665 3.33337 9.5396 3.33337 9.99984C3.33337 10.4601 3.70647 10.8332 4.16671 10.8332C11.172 10.8332 8.31424 10.8332 15.8334 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8334 9.1665C8.31425 9.1665 11.172 9.1665 4.16671 9.1665Z"
                                            fill="#939291"
                                          />
                                        </svg>
                                      </button>
                                      <div className="text-[18px] font-semibold">
                                        {numOfMinors}
                                      </div>
                                      <button
                                        data-testid="incrementMinors2"
                                        onClick={() =>
                                          setNumOfMinors(numOfMinors + 1)
                                        }
                                        className="rounded-btn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M10.8334 4.16683C10.8334 3.70659 10.4603 3.3335 10 3.3335C9.5398 3.3335 9.16671 3.70659 9.16671 4.16683V9.16683H4.16671C3.70647 9.16683 3.33337 9.53992 3.33337 10.0002C3.33337 10.4604 3.70647 10.8335 4.16671 10.8335H9.16671V15.8335C9.16671 16.2937 9.5398 16.6668 10 16.6668C10.4603 16.6668 10.8334 16.2937 10.8334 15.8335V10.8335H15.8334C16.2936 10.8335 16.6667 10.4604 16.6667 10.0002C16.6667 9.53993 16.2936 9.16683 15.8334 9.16683H10.8334V4.16683Z"
                                            fill="#B40303"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            <hr className="mt-8 mb-2" />

                            <div className="text-heading-3 mt-5">
                              Additional information
                            </div>

                            <Form.Item
                              colon={false}
                              label="Anything else we should know?"
                              name="message"
                              className="w-full mt-[32px] mb-[18rem]"
                            >
                              <Input.TextArea
                                className="border border-gray-400 h-[170px] md:h-[96px] roundedLg mb-5 pl-[16px] pt-[12px]"
                                rows={5}
                                placeholder="Enter your message..."
                                onChange={(e) => setMessage(e.target.value)}
                                maxLength={100}
                              />
                              <div className="w-full justify-end items-end text-oya-gray-200">
                                {message.length} / 100
                              </div>
                            </Form.Item>
                            {routePrices?.length <= 0 && (
                              <CustomButton
                                data-testid="submitAndContinue"
                                width="w-full"
                                onClick={() => {
                                  if (token) {
                                    bookInAdvance();
                                  } else {
                                    setStep(2);
                                  }
                                }}
                              >
                                <div>Continue</div>
                              </CustomButton>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <div className="text-heading-3 pb-[32px]">
                          Personal details
                        </div>

                        <div className="text-[16px] w-[80%] mt-3 mb-5">
                          Please provide your details to help reach out to you
                          when travel date is due.
                        </div>

                        <Form.Item
                          name="first_name"
                          label={
                            <label className="ml-2">Your first name</label>
                          }
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input className="h-[50px]" />
                        </Form.Item>
                        <Form.Item
                          name="last_name"
                          label={<label className="ml-2">Your last name</label>}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input className="h-[50px]" />
                        </Form.Item>
                        <Form.Item
                          colon={false}
                          label={
                            <label className="ml-2">Your mobile number</label>
                          }
                          name="guest_phone"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <PhoneInput
                            value={phone}
                            onChange={(e) => {
                              let formatted = e?.replace(/\s+/g, "");
                              setPhone(formatted);
                            }}
                            placeholder="Enter your mobile number"
                            className="phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none"
                            defaultCountry="GH"
                          />
                        </Form.Item>

                        <Form.Item
                          colon={false}
                          label={
                            <label className="ml-2">
                              Primary emergency contact
                            </label>
                          }
                          name="user_ice_phone"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <PhoneInput
                            value={icePhone}
                            onChange={(e) => {
                              let formatted = e?.replace(/\s+/g, "");
                              setIcePhone(formatted);
                            }}
                            placeholder="Enter your primary emergency number"
                            className="phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none"
                            defaultCountry="GH"
                          />
                        </Form.Item>

                        {!token && !fareId && (
                          <div className="flex gap-3 items-center w-full mt-5">
                            {submitting ? (
                              <Loader />
                            ) : (
                              <CustomButton
                                data-testid="bookAdvance"
                                width="w-full"
                                onClick={() => {
                                  //bookInAdvance();
                                  guestAdvanceTicket();
                                }}
                              >
                                <div>Book advance</div>
                              </CustomButton>
                            )}
                          </div>
                        )}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </>
                    )}
                  </>
                </Form>
              </div>
            </div>
            {routePrices.length > 0 && (
              <div className="fixed w-full bottom-0">
                <div className="shadow-[0_30px_50px_15px_rgba(0,0,0,0.3)] py-5 px-7 rounded-t-[12px] bg-white">
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="font-semibold">{schedule?.label}</div>

                      <div>
                        {showMoreSummary ? (
                          <button onClick={() => setShowMoreSummary(false)}>
                            <XMarkIcon className="w-6 h-6 text-oya-gray font-bold" />
                          </button>
                        ) : (
                          <button onClick={() => setShowMoreSummary(true)}>
                            <ChevronUpIcon className="w-6 h-6 text-oya-gray font-bold" />
                          </button>
                        )}
                      </div>
                    </div>
                    {selectedLoadingPoint && (
                      <div>
                        {selectedLoadingPoint?.loading_point?.label} |{" "}
                        {selectedLoadingPoint?.branch?.name}
                      </div>
                    )}
                    <div className="my-5">
                      {showMoreSummary && (
                        <>
                          <div className="flex justify-between items-center">
                            <div>Boarding</div>
                            <div className="text-right max-w-[50%]">
                              {pickupType === 0
                                ? "Bus terminal"
                                : `Mid-route (${selectedMidRoute?.label})`}
                            </div>
                          </div>
                          <div className="flex justify-between items-center py-1">
                            <div>Price</div>
                            {loadingPrices ? (
                              <div className="animate-pulse w-16 h-4 bg-slate-200" />
                            ) : (
                              <>
                                {priceDetails ? (
                                  <div>
                                    GHS
                                    {Intl.NumberFormat("en-EN").format(
                                      Number(priceDetails?.fare)
                                    )}
                                  </div>
                                ) : (
                                  <div>-</div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="flex justify-between items-center mb-1">
                            {pickupType === 0 ? (
                              <div>Service fee</div>
                            ) : (
                              <div>Mid-route fee</div>
                            )}
                            {loadingPrices ? (
                              <div className="animate-pulse w-16 h-4 bg-slate-200" />
                            ) : (
                              <>
                                {priceDetails ? (
                                  <div>
                                    GHS
                                    {Intl.NumberFormat("en-EN").format(
                                      Number(priceDetails?.serviceFee)
                                    )}
                                  </div>
                                ) : (
                                  <div>-</div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                      <div className="flex justify-between items-center">
                        <div>Total</div>
                        {loadingPrices ? (
                          <div className="animate-pulse w-16 h-4 bg-slate-200" />
                        ) : (
                          <>
                            {priceDetails ? (
                              <div>
                                GHS
                                {Intl.NumberFormat("en-EN").format(
                                  Number(priceDetails?.amountPayable)
                                )}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-3 items-center w-full">
                      {submitting ? (
                        <Loader />
                      ) : (
                        <CustomButton
                          data-testid="submitAndContinue"
                          width="w-full"
                          onClick={() => {
                            submit();
                          }}
                        >
                          <div>Continue</div>
                        </CustomButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <AuthPrompt
            isOpen={promptUser}
            handleClosed={() => setPrompt(false)}
            handleGuest={() => {
              setPrompt(false);
              setStep(2);
            }}
          />
          <BusStopModal
            selected={selectedMidRoute}
            pickups={pickups}
            isOpen={busStopModal}
            handleClose={() => {
              openBusStopModal(false);
              if (!selectedMidRoute) {
                setPickupType(0);
              }
            }}
            handleSelectedStop={(pickup) => {
              setPickupType(1);
              setSelectedMidRoute(pickup);
              openBusStopModal(false);
            }}
            routeId={form.getFieldValue("route_id")}
          />
          <LuggageModal
            isOpen={luggageModal}
            handleClose={() => {
              openLuggageModal(false);
              if (!selectedLuggage) {
                setLuggageType(2);
              }
            }}
            handleSelectedStop={(type) => {
              setLuggageType(3);
              openLuggageModal(false);
              setSelectedLuggage(type);

              //console.log(type);
            }}
            luggages={luggageSizes}
          />

          <Modal open={success} footer={null} closable={false}>
            <div>
              <div className="w-full flex justify-end items-end">
                <button
                  data-testid="advanceBookingSuccess"
                  onClick={() => {
                    setSuccess(false);
                  }}
                >
                  <XMarkIcon className="w-[20px] h-[20px] text-oya-gray" />
                </button>
              </div>
              <div className="flex flex-col justify-center mt-4 items-center gap-3">
                {/* <img src={successImg} alt='processing' /> */}
                <div className="border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="18"
                    viewBox="0 0 25 18"
                    fill="none"
                  >
                    <path
                      d="M1.5 9.29247L8.79247 16.5849L23.34 2"
                      stroke="#2ABB7F"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="text-center text-[24px] font-semibold text-seat-green py-2">
                  Booking successful!
                </div>
                <div className="text-[16px] text-center">
                  Your trip has been booked successfully.
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default BookInAdvanceMobile;
